/*******************************  Activate account *****************************************************/
import { React, Link, Button } from "../commonFiles/Import";
import { VscVmActive } from "../commonFiles/Icons";

const Activate = () => {
  return (
    <>
      {/* left animation background */}
      <div className="left-bg-gradient position-fixed "></div>

      {/* activation section */}
      <section className="activation-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className="col-xl-5 col-md-7 col-lg-6">
              <div className="card bg-glass">
                <div className="card-body text-center">
                  <VscVmActive
                    color="var( --success-color)"
                    size="100"
                    className="mb-4 "
                  />
                  <h1 className="card-title fs-2 fw-bold ">
                    <span>Activation Completed!</span>
                  </h1>

                  <p className="mt-3 fs-6">
                    Your Account has been successfully activated. You can now
                    log in using the email address and password you choose
                    during the registration.
                  </p>

                  <Button type="button" className="primary-button shadow">
                    <Link to="/" className="text-white">
                      click here to Login
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* right animation background */}
      <div className="right-bg-gradient position-fixed "></div>
    </>
  );
};

export default Activate;
