/******************************** reset password page *****************************************/

import {
  React,
  TextField,
  useState,
  Popover,
  LoadingButton,
  axios,
  swal,
  useHistory,
} from "../commonFiles/Import";
import { FcUnlock, FcInfo } from "../commonFiles/Icons";

const ResetPassword = (props) => {
  //used for redirecting page
  const history = useHistory();

  //used states
  const [usedStates, setUsedStates] = useState({
    //password details
    passwordDetails: {
      password: "",
      confirmPassword: "",
      activationCode: props.match.params.activationCode,
    },

    // error_fields
    errors: {
      passwordError: "",
      confirmPasswordError: "",
    },

    additonalStates: {
      //loading button
      loading: false,
    },
  });

  // popover state
  const [anchorEl, setAnchorEl] = useState(null);

  /********************* destructering objects start *************** */
  const { passwordDetails } = usedStates;
  const { password, confirmPassword } = usedStates.passwordDetails;

  const { passwordError, confirmPasswordError } = usedStates.errors;

  const { loading } = usedStates.additonalStates;

  /********************* destructering objects end *************** */

  //hadnle input fields
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        passwordDetails: {
          ...prevValue.passwordDetails,
          [name]: value,
        },
      };
    });
  };

  //handle reset errors
  const handleResetError = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  //on submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          additonalStates: {
            ...prevValue.additonalStates,
            loading: true,
          },
        };
      });

      //send data
      const result = await axios.patch("/change-password", passwordDetails);

      //on success
      if (result.data) {
        const { success } = result.data;

        if (success) {
          setUsedStates((prevValue) => {
            return {
              ...prevValue,

              //reset form
              passwordDetails: {
                ...prevValue.passwordDetails,
                password: "",
                confirmPassword: "",
              },

              //reset error
              errors: {
                ...prevValue.errors,
                passwordError: "",
                confirmPasswordError: "",
              },

              additonalStates: {
                ...prevValue.additonalStates,

                //disable loading
                loading: false,
              },
            };
          });

          // show message to user
          swal({
            title: "success",
            text: "Your password is updated successfully",
            icon: "success",
          }).then((value) => {
            if (value) {
              //redirect to login page
              history.push("/");
            }
          });
        }
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          additonalStates: {
            ...prevValue.additonalStates,
            loading: false,
          },
        };
      });

      if (err.response.data) {
        // show errors to user
        const { errors } = err.response.data;

        //destructering objects
        const { activationCodeError, confirmPasswordError, passwordError } =
          errors;

        //assign errors
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              passwordError,
              confirmPasswordError,
            },
          };
        });

        //invalid account
        if (activationCodeError !== "") {
          swal({
            title: `Error`,
            text: activationCodeError,
            icon: "error",
          });
        }
      } else {
        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };
  return (
    <>
      {/* left animation background */}
      <div className="left-bg-gradient  "></div>

      {/* reset section */}
      <section className="reset-section">
        <div className="container">
          <div className="row d-flex align-items-center justify-content-center min-vh-100">
            <div className="col-xl-5 col-md-7 col-lg-6">
              <div className="card bg-glass">
                <div className="card-body text-center">
                  <FcUnlock size="100" className="mb-2" />

                  <h1 className="fs-2 fw-bold card-title">Reset password</h1>

                  {/* reset password form */}
                  <form method="POST" onSubmit={handleSubmit}>
                    {/* password */}
                    <TextField
                      label="Enter password"
                      variant="standard"
                      className="w-100 mt-2"
                      name="password"
                      id="password"
                      onChange={(event) => {
                        handleInputEvent(event);

                        handleResetError("passwordError");
                      }}
                      type="password"
                      error={passwordError ? true : false}
                      helperText={passwordError}
                      value={password}
                    />

                    {/********************************* password info  start  *********************** */}
                    <FcInfo
                      style={{
                        right: "1rem",
                        cursor: "pointer",
                        marginTop: "2rem",
                        color: "var(--primary-color)",
                      }}
                      size={20}
                      aria-owns={
                        Boolean(anchorEl) ? "mouse-over-popover" : undefined
                      }
                      aria-haspopup="true"
                      onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                      onMouseLeave={() => setAnchorEl(null)}
                      className="text-primary position-absolute"
                    />

                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      onClose={() => setAnchorEl(null)}
                      disableRestoreFocus
                    >
                      <div className="p-3">
                        <span className="fw-bold">
                          The password must include
                        </span>
                        <ul style={{ listStyleType: "circle" }}>
                          <li>length should be minimum 8 characters.</li>
                          <li>atleast one numeric character.</li>
                          <li>atleast one Alphabetic letter.</li>
                          <li>atleast one capital letter.</li>
                          <li>one special character.</li>
                          <li>should not contain white space.</li>
                        </ul>
                      </div>
                    </Popover>

                    {/********************************* password info  end  *********************** */}

                    {/* repassword */}
                    <TextField
                      label="Confirm password"
                      variant="standard"
                      className="w-100 mt-2"
                      name="confirmPassword"
                      id="confirmPassword"
                      onChange={(event) => {
                        handleInputEvent(event);

                        handleResetError("confirmPasswordError");
                      }}
                      type="password"
                      error={confirmPasswordError ? true : false}
                      helperText={confirmPasswordError}
                      value={confirmPassword}
                    />

                    {/* reset password button */}
                    <LoadingButton
                      type="submit"
                      variant="outlined"
                      loading={loading}
                      loadingIndicator="Loading"
                      className={
                        loading
                          ? "primary-border shadow  mt-3"
                          : "primary-button  shadow   mt-3  "
                      }
                    >
                      reset password
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* right animation background */}
      <div className="right-bg-gradient position-fixed bottom-0"></div>
    </>
  );
};

export default ResetPassword;
