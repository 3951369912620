/*************************************** sidebar page *****************************************/

import {
  React,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  useHistory,
  swal,
  Cookies,
} from "./Import";

import { avatar } from "./Images";

import { RiYoutubeLine, RiLogoutBoxLine, BiMessageDots } from "./Icons";

const SideBar = (props) => {
  // used for redirecting pages
  const history = useHistory();

  // logout
  const logout = () => {
    swal({
      text: "You have been logged out successfully.",
      timer: 4000,
      buttons: false,
      icon: "success",
    }).then(
      () =>
        //reset session
        window.sessionStorage.clear(),

      //destroy cookies
      Cookies.get("token")
        ? Cookies.remove("token", { expires: Date.now() })
        : null,

      // redirect to home page
      history.push("/")
    );
  };

  return (
    <>
      {/* sidebar section */}
      <section className="sideBar-section">
        {/* username */}
        <div className="user text-center">
          <img
            src={avatar}
            alt="avatar.svg"
            width={80}
            height={80}
            className=" img-fluid mt-2 shadow"
          />
        </div>
        <hr />

        {/* menu */}
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          className="menu"
        >
          {/* tickets */}
          <ListItem
            button
            onClick={() => {
              // close sidebar
              props.closeDrawer();

              history.push("/tickets");
            }}
          >
            <ListItemIcon>
              <BiMessageDots />
            </ListItemIcon>
            <ListItemText primary="Tickets" />
          </ListItem>

          {/* videos */}
          <ListItem
            button
            onClick={() => {
              // close sidebar
              props.closeDrawer();

              history.push({
                pathname: "/videos",
                state: {
                  productType: sessionStorage.getItem("productType"),
                },
              });
            }}
          >
            <ListItemIcon>
              <RiYoutubeLine />
            </ListItemIcon>
            <ListItemText primary="Videos" />
          </ListItem>

          {/* logout */}
          <ListItem
            button
            onClick={() => {
              logout();

              // close sidebar
              props.closeDrawer();
            }}
          >
            <ListItemIcon>
              <RiLogoutBoxLine />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </List>
      </section>

      {/* right animation background */}
      <div className="right-bg-gradient"></div>
    </>
  );
};

export default SideBar;
