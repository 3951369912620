/*******************************  Video page ********************************************* */

import {
  React,
  Header,
  useState,
  useRef,
  ReactPlayer,
  getYouTubeID,
  PlayerControls,
  screenfull,
  useEffect,
  useHistory,
  checkCookieExpiry,
  swal,
  useLocation,
  Cookies,
  CDRPlaylist,
  FDAPlaylist,
} from "../commonFiles/Import";

//format duration and currentTime
const format = (seconds) => {
  if (isNaN(seconds)) {
    return "00:00";
  }

  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");

  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }

  return `${mm}:${ss}`;
};

let count = 0;

const Videos = () => {
  const location = useLocation();

  const { productType } = location.state;

  //used for redirecting page
  const history = useHistory();

  //player options initial states
  const [player, setPlayer] = useState({
    playing: false,
    muted: false,
    played: 0,
    seeking: false,
    url:
      productType === "FDA"
        ? "https://www.youtube.com/embed/wt_ayJR_L_c"
        : "https://www.youtube.com/embed/Yhs_3eoIHjk",
  });

  //playlist data
  const [playlist_data, setPlaylistData] = useState([]);

  const playerRef = useRef(null);
  const playerContainerRef = useRef(null);
  const controlsRef = useRef(null);

  const { playing, muted, played, seeking, url } = player;

  const currentTime = playerRef.current
    ? playerRef.current.getCurrentTime()
    : "00:00";
  const duration = playerRef.current
    ? playerRef.current.getDuration()
    : "00:00";

  const elapsedTime = format(currentTime);
  const totalDuration = format(duration);

  useEffect(() => {
    //check if cookie is expired
    if (checkCookieExpiry()) {
      swal({
        title: "Sorry !!!",
        text: "Your session is expired please Login.",
        timer: 4000,
        buttons: false,
        icon: "info",
      }).then(
        () =>
          //reset session
          window.sessionStorage.clear(),

        //destroy cookies
        Cookies.get("token")
          ? Cookies.remove("token", { expires: Date.now() })
          : null,

        // redirect to home page
        history.push("/")
      );
    } else {
      //assign playlist data based on prodcut type
      setPlaylistData(productType === "FDA" ? FDAPlaylist : CDRPlaylist);
    }
  }, [history, productType]);

  //change URL based on user click
  const changeIframe = (event) => {
    const newSRC = event.target.getAttribute("data-url");

    //assign data
    setPlayer((prevValue) => {
      return {
        ...prevValue,
        url: newSRC,
        played: 0,
      };
    });
  };

  //play and pause Video
  const onPlayPause = () => {
    setPlayer({
      ...player,
      playing: !player.playing,
    });
  };

  //rewind 10 sec
  const onRewind = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() - 10);
  };

  //Forward 10 sec
  const onForward = () => {
    playerRef.current.seekTo(playerRef.current.getCurrentTime() + 10);
  };

  //on mute
  const onMute = () => {
    setPlayer({
      ...player,
      muted: !player.muted,
    });
  };

  //full screen
  const onToggleFullScreen = () => {
    screenfull.toggle(playerContainerRef.current);
  };

  //progress bar
  const onProgress = (changestate) => {
    if (count > 3) {
      controlsRef.current.style.visibility = "hidden";
      count = 0;
    }

    if (controlsRef.current.style.visibility === "visible") {
      ++count;
    }

    if (!seeking) {
      setPlayer({ ...player, ...changestate });
    }
  };

  //progress bar function
  const onSeekChange = (e, newValue) => {
    setPlayer({ ...player, played: parseFloat(newValue / 100) });
  };

  //progress bar function
  const onSeekMouseDown = (e) => {
    setPlayer({ ...player, seeking: true });
  };

  //progress bar function
  const onSeekMouseUp = (e, newValue) => {
    setPlayer({ ...player, seeking: false });
    playerRef.current.seekTo(newValue / 100);
  };

  //hide controls when video playing
  const onMouseMove = () => {
    controlsRef.current.style.visibility = "visible";
    count = 0;
  };

  //play next video
  const playNextVideo = () => {
    for (let index = 0; index < playlist_data.length; index++) {
      //get url from playlist
      const next_url = playlist_data[index].url;

      if (getYouTubeID(url) === getYouTubeID(next_url)) {
        setPlayer((prevValue) => {
          return {
            ...prevValue,
            url: playlist_data[++index].url,
            played: 0,
          };
        });

        break;
      } else if (
        getYouTubeID(url) ===
        getYouTubeID(playlist_data[playlist_data.length - 1].url)
      ) {
        setPlayer((prevValue) => {
          return {
            ...prevValue,
            url: playlist_data[index].url,
            played: 0,
          };
        });
        break;
      }
    }
  };

  //play previous video
  const playPreviousVideo = () => {
    for (let index = 0; index < playlist_data.length; index++) {
      //get url from playlist
      const prev_url = playlist_data[index].url;

      if (getYouTubeID(url) === getYouTubeID(prev_url)) {
        if (index === 0) {
          setPlayer((prevValue) => {
            return {
              ...prevValue,
              url: playlist_data[index].url,
              played: 0,
            };
          });

          break;
        } else {
          setPlayer((prevValue) => {
            return {
              ...prevValue,
              url: playlist_data[--index].url,
              played: 0,
            };
          });
          break;
        }
      }
    }
  };

  //disableRightClick
  const disableRightClick = (e) => {
    if (location.pathname === "/videos") {
      e.preventDefault();
    }
  };

  return (
    <div onContextMenu={disableRightClick}>
      {/* header */}
      <Header />

      {/* left animation background */}
      <div className="left-bg-gradient "></div>

      <section className="youtube-section" style={{ marginTop: "6rem" }}>
        <div className="container-fluid">
          <div className="row g-4">
            {/* Youtube Player */}
            <div className="col-md-7">
              <div className="card bg-glass h-100">
                <h3 className="card-header fw-bold">Video player</h3>

                <div className="card-body ">
                  {/* video player */}
                  <div
                    className=" p-0  youtube-palyer card bg-glass  border-0 "
                    ref={playerContainerRef}
                    onMouseMove={onMouseMove}
                  >
                    <ReactPlayer
                      muted={muted}
                      ref={playerRef}
                      playing={playing}
                      id="ytplayer"
                      url={url}
                      onProgress={onProgress}
                      onEnded={() =>
                        setPlayer((prevValue) => {
                          return {
                            ...prevValue,
                            playing: false,
                          };
                        })
                      }
                    />
                    <PlayerControls
                      ref={controlsRef}
                      onMute={onMute}
                      muted={muted}
                      onPlayPause={onPlayPause}
                      playing={playing}
                      onRewind={onRewind}
                      onForward={onForward}
                      onToggleFullScreen={onToggleFullScreen}
                      played={played}
                      onSeek={onSeekChange}
                      onSeekMouseUp={onSeekMouseUp}
                      onSeekMouseDown={onSeekMouseDown}
                      elapsedTime={elapsedTime}
                      totalDuration={totalDuration}
                      playNextVideo={playNextVideo}
                      playPreviousVideo={playPreviousVideo}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Youtube playlist */}
            <div className="col-md  play-list ">
              <div className="card bg-glass h-100 ">
                <h3 className="card-header fw-bold  position-fixed w-100 top-0 ">
                  Playlist
                </h3>
                <div className="card-body">
                  {playlist_data.map((data, index) => {
                    return (
                      <div className="card mb-2 bg-glass" key={index}>
                        <div className="d-flex">
                          <div className="flex-shrink-0">
                            <img
                              src={data.src}
                              data-url={data.url}
                              title="play video"
                              id="next-play"
                              alt={data.alt}
                              className="img-fluid img-thumbnail"
                              onClick={changeIframe}
                              style={{ cursor: "pointer" }}
                            />
                          </div>
                          <div className="flex-grow-1 ms-3 fw-bold">
                            {data.title}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* right animation background */}
      <div className="right-bg-gradient bottom-0 position-fixed "></div>
    </div>
  );
};

export default Videos;
