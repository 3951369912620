/*******************************  Page Not found *****************************************************/

import { React, Button, Link } from "./commonFiles/Import";

import { MdError } from "@react-icons/all-files/md/MdError";

const PageNotFound = () => {
  return (
    <>
      {/* left animation background */}
      <div className="left-bg-gradient position-fixed "></div>

      {/* error section */}
      <section className="error-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className="col-xl-5 col-md-7 col-lg-6">
              <div className="card bg-glass">
                <div className="card-body text-center">
                  <MdError
                    color="var(--red-color)"
                    size="100"
                    className="mb-2 "
                  />
                  <h1 className="card-title fs-2 fw-bold d-flex flex-column ">
                    <span>OOPs! </span>
                    <span>Page Not found</span>
                  </h1>

                  <p className="mt-3 fs-6">
                    The Page you're looking for does not exist. it might have
                    been moved or deleted.
                  </p>

                  <Button type="button" className="primary-button shadow">
                    <Link to="/" className="text-white">
                      go back
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* right animation background */}
      <div className="right-bg-gradient position-fixed "></div>
    </>
  );
};

export default PageNotFound;
