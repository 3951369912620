/*************************************** forgotPassword page *****************************************/

import {
  React,
  TextField,
  LoadingButton,
  useState,
  axios,
  swal,
} from "../commonFiles/Import";
import { FcLock } from "../commonFiles/Icons";

const ForgotPassword = () => {
  //used States
  const [usedStates, setUsedStates] = useState({
    //forgot details
    forgotDetails: {
      email: "",
    },

    //error
    errors: {
      emailError: "",
    },

    //addition states
    additionalStates: {
      loading: false,
    },
  });

  /*********************************************  destructering objects start ***********************************************/
  const { loading } = usedStates.additionalStates;

  const { forgotDetails } = usedStates;

  const { emailError } = usedStates.errors;

  const { email } = forgotDetails;

  /*********************************************  destructering objects end ***********************************************/

  //on submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          additionalStates: {
            ...prevValue.additionalStates,
            loading: true,
          },
        };
      });

      //send data
      const result = await axios.post("/forgot-password", forgotDetails);

      //on success
      if (result.data) {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,

            //reset form
            additionalStates: {
              ...prevValue.additionalStates,
              loading: false,
            },

            //reset Form
            forgotDetails: {
              ...prevValue.forgotDetails,
              email: "",
            },

            //reset Errors
            errors: {
              ...prevValue.errors,
              emailError: "",
            },
          };
        });

        const { success } = result.data;

        //show success message to user
        swal({ title: "success", text: success, icon: "success" });
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          additionalStates: {
            ...prevValue.additionalStates,
            loading: false,
          },
        };
      });

      // show errors to user
      const { errors } = err.response.data;

      if (errors) {
        //destructering objects
        const { emailError } = errors;

        //assign errors
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              emailError,
            },
          };
        });
      } else {
        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };
  return (
    <>
      {/* left animation background */}
      <div className="left-bg-gradient"></div>

      {/* forgot password */}
      <section className="forgot-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className="col-xl-5 col-md-7 col-lg-6">
              <div className="card bg-glass">
                <div className="card-body text-center">
                  <FcLock size="100" className="mb-2" />
                  <h1 className=" card-title fs-2   fw-bold d-flex flex-column">
                    <span>Forgot</span>
                    <span>Your password ?</span>
                  </h1>
                  <p className="mt-3">
                    That's okay. it happens ! You can reset your password here.
                  </p>

                  {/* forgot password  form */}
                  <form
                    method="POST"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="form-group"
                  >
                    {/* email address */}
                    <TextField
                      label="Email address"
                      variant="standard"
                      type="email"
                      name="email"
                      id="email"
                      className="w-100 "
                      onChange={(event) => {
                        setUsedStates((prevValue) => {
                          return {
                            ...prevValue,
                            forgotDetails: {
                              ...prevValue.forgotDetails,
                              email: event.target.value,
                            },
                          };
                        });

                        setUsedStates((prevValue) => {
                          return {
                            ...prevValue,
                            errors: {
                              ...prevValue.errors,
                              emailError: "",
                            },
                          };
                        });
                      }}
                      value={email}
                      error={emailError ? true : false}
                      helperText={emailError}
                    />

                    {/* send link */}
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      loadingIndicator="Loading"
                      className={
                        loading
                          ? "primary-border shadow mt-3 "
                          : "primary-button shadow mt-3 "
                      }
                    >
                      send Link
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* right animation background */}
      <div className="right-bg-gradient position-fixed  bottom-0"></div>
    </>
  );
};

export default ForgotPassword;
