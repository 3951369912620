/*******************************************Youtube playlist *******************************/

const CDRPlaylist = [
  //video [TDR Import Process Single Area]
  {
    src: "https://img.youtube.com/vi/Yhs_3eoIHjk/1.jpg",
    url: " https://www.youtube.com/embed/Yhs_3eoIHjk",
    title: "TDR Import Process Single Area",
    alt: "video1.jpg",
  },

  //video [TDR Import of Multiple Areas at One Time]
  {
    src: "https://img.youtube.com/vi/hlAMpHUV4lU/1.jpg",
    url: "https://www.youtube.com/embed/hlAMpHUV4lU",
    title: "TDR Import of Multiple Areas at One Time",
    alt: "video2.jpg",
  },

  //video [Skipped rows Re-import]
  {
    src: "https://img.youtube.com/vi/djQYqytjrPI/1.jpg",
    url: "https://www.youtube.com/embed/djQYqytjrPI",
    title: "Skipped rows Re-import",
    alt: "video3.jpg",
  },

  //video [Show previous and next call of Target Number]
  {
    src: "https://img.youtube.com/vi/T891DixRwJQ/1.jpg",
    url: "https://www.youtube.com/embed/T891DixRwJQ",
    title: "Show previous and next call of Target Number",
    alt: "video4.jpg",
  },

  //video [Sheet Formatting]
  {
    src: "https://img.youtube.com/vi/fJWZ5sIl3T0/1.jpg",
    url: "https://www.youtube.com/embed/fJWZ5sIl3T0",
    title: "Sheet Formatting",
    alt: "video5.jpg",
  },

  //video [SDR Manual Import]
  {
    src: "https://img.youtube.com/vi/p-BIBeWWPaA/1.jpg",
    url: "https://www.youtube.com/embed/p-BIBeWWPaA",
    title: "SDR Manual Import",
    alt: "video6.jpg",
  },

  //video [SDR Import Log Report]
  {
    src: "https://img.youtube.com/vi/zqC8OfBtgck/1.jpg",
    url: "https://www.youtube.com/embed/zqC8OfBtgck",
    title: "SDR Import Log Report",
    alt: "video7.jpg",
  },

  //video [SDR Import]
  {
    src: "https://img.youtube.com/vi/dbNG8x1DZPI/1.jpg",
    url: "https://www.youtube.com/embed/dbNG8x1DZPI",
    title: "SDR Import",
    alt: "video8.jpg",
  },

  //video [Quick Analysis Single CDR]
  {
    src: "https://img.youtube.com/vi/HeURBiODsZ0/1.jpg",
    url: "https://www.youtube.com/embed/HeURBiODsZ0",
    title: "Quick Analysis Single CDR",
    alt: "video9.jpg",
  },

  //video [Quick Analysis Multiple CDRs]
  {
    src: "https://img.youtube.com/vi/-KUVytycqeU/1.jpg",
    url: "https://www.youtube.com/embed/-KUVytycqeU",
    title: "Quick Analysis Multiple CDRs",
    alt: "video10.jpg",
  },

  //video [Pattern Analysis]
  {
    src: "https://img.youtube.com/vi/eAxfQSAgmRE/1.jpg",
    url: "https://www.youtube.com/embed/eAxfQSAgmRE",
    title: "Pattern Analysis",
    alt: "video11.jpg",
  },

  //video [Number Tracking]
  {
    src: "https://img.youtube.com/vi/fr-SV6WmFRU/1.jpg",
    url: "https://www.youtube.com/embed/fr-SV6WmFRU",
    title: "Number Tracking",
    alt: "video12.jpg",
  },

  //video [Night Calls]
  {
    src: "https://img.youtube.com/vi/eZqh-T4NltA/1.jpg",
    url: "https://www.youtube.com/embed/eZqh-T4NltA",
    title: "Night Calls",
    alt: "video13.jpg",
  },

  //video [Multiple Place TDR Importing process]
  {
    src: "https://img.youtube.com/vi/SwjhMocXmLM/1.jpg",
    url: "https://www.youtube.com/embed/SwjhMocXmLM",
    title: "Multiple Place TDR Importing process",
    alt: "video14.jpg",
  },

  //video [Multiple CDR Import]
  {
    src: "https://img.youtube.com/vi/UAaUXPHUewU/1.jpg",
    url: "https://www.youtube.com/embed/UAaUXPHUewU",
    title: "Multiple CDR Import",
    alt: "video15.jpg",
  },

  //video [More Option - Service Number Suspect List]
  {
    src: "https://img.youtube.com/vi/UcbpMJCdyOA/1.jpg",
    url: "https://www.youtube.com/embed/UcbpMJCdyOA",
    title: "Service Number Suspect List",
    alt: "video16.jpg",
  },

  //video [More Option - New Number Note]
  {
    src: "https://img.youtube.com/vi/L3k-uqtRS2A/1.jpg",
    url: "https://www.youtube.com/embed/L3k-uqtRS2A",
    title: "More Option - New Number Note",
    alt: "video17.jpg",
  },

  //video [More Option Insert Photo Audio Video]
  {
    src: "https://img.youtube.com/vi/PPmDM-bXBsk/1.jpg",
    url: "https://www.youtube.com/embed/PPmDM-bXBsk",
    title: "More Option - New Number Note",
    alt: "video18.jpg",
  },

  //video [More Options - Copy Scramble Highlight]
  {
    src: "https://img.youtube.com/vi/ve2jwjDVVEU/1.jpg",
    url: "https://www.youtube.com/embed/ve2jwjDVVEU",
    title: "More Options - Copy Scramble Highlight",
    alt: "video19.jpg",
  },

  //video [Match With Other DB]
  {
    src: "https://img.youtube.com/vi/ulHbfluHAXA/1.jpg",
    url: "https://www.youtube.com/embed/ulHbfluHAXA",
    title: "Match With Other DB",
    alt: "video20.jpg",
  },

  //video Match With GeoFence]
  {
    src: "https://img.youtube.com/vi/3THYYwfwSFw/1.jpg",
    url: "https://www.youtube.com/embed/3THYYwfwSFw",
    title: "Match With GeoFence",
    alt: "video21.jpg",
  },

  //video [Match with External Files]
  {
    src: "https://img.youtube.com/vi/zOWD-gpYzs8/1.jpg",
    url: "https://www.youtube.com/embed/zOWD-gpYzs8",
    title: "Match with External Files",
    alt: "video22.jpg",
  },

  //video [Match Unmatch Number]
  {
    src: "https://img.youtube.com/vi/pQTub5Gnn3g/1.jpg",
    url: "https://www.youtube.com/embed/pQTub5Gnn3g",
    title: "Match Unmatch Number",
    alt: "video23.jpg",
  },

  //video [IPDR Manual Importing]
  {
    src: "https://img.youtube.com/vi/FB1DklFWNpM/1.jpg",
    url: "https://www.youtube.com/embed/FB1DklFWNpM",
    title: "IPDR Manual Importing",
    alt: "video24.jpg",
  },

  //video [In Roamers]
  {
    src: "https://img.youtube.com/vi/6yyfZEhwxZI/1.jpg",
    url: "https://www.youtube.com/embed/6yyfZEhwxZI",
    title: "In Roamers",
    alt: "video25.jpg",
  },

  //video [IIMEI Import]
  {
    src: "https://img.youtube.com/vi/kUqDbVAPG2w/1.jpg",
    url: "https://www.youtube.com/embed/kUqDbVAPG2w",
    title: "IIMEI Import",
    alt: "video26.jpg",
  },

  //video [Geo Movements]
  {
    src: "https://img.youtube.com/vi/Wgh53IcO2ok/1.jpg",
    url: "https://www.youtube.com/embed/Wgh53IcO2ok",
    title: "Geo Movements",
    alt: "video27.jpg",
  },

  //video [Geo Fence]
  {
    src: "https://img.youtube.com/vi/mVw1D2eeWgM/1.jpg",
    url: "https://www.youtube.com/embed/mVw1D2eeWgM",
    title: "Geo Fence",
    alt: "video28.jpg",
  },

  //video [Geo Crime Mapping]
  {
    src: "https://img.youtube.com/vi/CfTkAm1pVRg/1.jpg",
    url: "https://www.youtube.com/embed/CfTkAm1pVRg",
    title: "Geo Crime Mapping",
    alt: "video29.jpg",
  },

  //video [Geo Analysis]
  {
    src: "https://img.youtube.com/vi/XOoCdDpQhhM/1.jpg",
    url: "https://www.youtube.com/embed/XOoCdDpQhhM",
    title: "Geo Analysis",
    alt: "video30.jpg",
  },

  //video [Fixed Calls and Conference calls]
  {
    src: "https://img.youtube.com/vi/aFh4Hn63TsY/1.jpg",
    url: "https://www.youtube.com/embed/aFh4Hn63TsY",
    title: "Fixed Calls and Conference calls",
    alt: "video31.jpg",
  },

  //video [Filter within Analysis]
  {
    src: "https://img.youtube.com/vi/vadZsH8zowM/1.jpg",
    url: "https://www.youtube.com/embed/vadZsH8zowM",
    title: "Filter within Analysis",
    alt: "video32.jpg",
  },

  //video [Details and Both Party Present]
  {
    src: "https://img.youtube.com/vi/FJydrHLynE0/1.jpg",
    url: "https://www.youtube.com/embed/FJydrHLynE0",
    title: "Details and Both Party Present",
    alt: "video33.jpg",
  },

  //video [Delete SDR Data]
  {
    src: "https://img.youtube.com/vi/qJGXVKuTEp8/1.jpg",
    url: "https://www.youtube.com/embed/qJGXVKuTEp8",
    title: "Delete SDR Data",
    alt: "video34.jpg",
  },

  //video [Delete IPDR Data Analysis]
  {
    src: "https://img.youtube.com/vi/Kv3wH8EKjM4/1.jpg",
    url: "https://www.youtube.com/embed/Kv3wH8EKjM4",
    title: "Delete IPDR Data Analysis",
    alt: "video35.jpg",
  },

  //video [Delete Cell ID Data]]
  {
    src: "https://img.youtube.com/vi/pSiNbQfD1uY/1.jpg",
    url: "https://www.youtube.com/embed/pSiNbQfD1uY",
    title: "Delete Cell ID Data",
    alt: "video36.jpg",
  },

  //video [Delete CDR Data and Analysis]
  {
    src: "https://img.youtube.com/vi/xmBukM_KJsw/1.jpg",
    url: "https://www.youtube.com/embed/xmBukM_KJsw",
    title: "Delete CDR Data and Analysis",
    alt: "video37.jpg",
  },

  //video [Days Present]
  {
    src: "https://img.youtube.com/vi/C6xzOemOFqU/1.jpg",
    url: "https://www.youtube.com/embed/C6xzOemOFqU",
    title: "Days Present",
    alt: "video38.jpg",
  },

  //video [Dates when no calls made]
  {
    src: "https://img.youtube.com/vi/VP-8B5iXILk/1.jpg",
    url: "https://www.youtube.com/embed/VP-8B5iXILk",
    title: "Dates when no calls made",
    alt: "video39.jpg",
  },

  //video [Dashboard of Combined analysis]
  {
    src: "https://img.youtube.com/vi/9JNBdhVdYGw/1.jpg",
    url: "https://www.youtube.com/embed/9JNBdhVdYGw",
    title: "Dashboard of Combined analysis",
    alt: "video40.jpg",
  },

  //video [Dashboard Location]
  {
    src: "https://img.youtube.com/vi/rbyzuS2uZwg/1.jpg",
    url: "https://www.youtube.com/embed/rbyzuS2uZwg",
    title: "Dashboard Location",
    alt: "video41.jpg",
  },

  //video [Dashboard Frequency]
  {
    src: "https://img.youtube.com/vi/rkxNC4PNPU8/1.jpg",
    url: "https://www.youtube.com/embed/rkxNC4PNPU8",
    title: "Dashboard Frequency",
    alt: "video42.jpg",
  },

  //video [Dashboard Analysis]
  {
    src: "https://img.youtube.com/vi/GeyfSNW2z0c/1.jpg",
    url: "https://www.youtube.com/embed/GeyfSNW2z0c",
    title: "Dashboard Analysis",
    alt: "video43.jpg",
  },

  //video [Create Sub Analysis]
  {
    src: "https://img.youtube.com/vi/cVmya9p-_68/1.jpg",
    url: "https://www.youtube.com/embed/cVmya9p-_68",
    title: "Create Sub Analysis",
    alt: "video44.jpg",
  },

  //video [Contacts Primary and Secondary]
  {
    src: "https://img.youtube.com/vi/CrMBj4chzsk/1.jpg",
    url: "https://www.youtube.com/embed/CrMBj4chzsk",
    title: "Contacts Primary and Secondary",
    alt: "video45.jpg",
  },

  //video [Cell ID Import Log Report]
  {
    src: "https://img.youtube.com/vi/mILhBLg4dJY/1.jpg",
    url: "https://www.youtube.com/embed/mILhBLg4dJY",
    title: "Cell ID Import Log Report",
    alt: "video46.jpg",
  },

  //video [Cell ID Import]
  {
    src: "https://img.youtube.com/vi/05rw5k7G9tI/1.jpg",
    url: "https://www.youtube.com/embed/05rw5k7G9tI",
    title: "Cell ID Import",
    alt: "video47.jpg",
  },

  //video [CDR Import Manually]
  {
    src: "https://img.youtube.com/vi/IktlVFdOJu8/1.jpg",
    url: "https://www.youtube.com/embed/IktlVFdOJu8",
    title: "CDR Import Manually",
    alt: "video48.jpg",
  },

  //video [CDR Import Log Report]
  {
    src: "https://img.youtube.com/vi/ia_Owdghuo0/1.jpg",
    url: "https://www.youtube.com/embed/ia_Owdghuo0",
    title: "CDR Import Log Report",
    alt: "video49.jpg",
  },

  //video [CDR Import Avoid Duplicates in CDR]
  {
    src: "https://img.youtube.com/vi/76uJlB-DgTs/1.jpg",
    url: "https://www.youtube.com/embed/76uJlB-DgTs",
    title: "CDR Import Avoid Duplicates in CDR",
    alt: "video50.jpg",
  },

  //video [CDR Import Avoid Blank Call Types]
  {
    src: "https://img.youtube.com/vi/0Ui9tf2Ld0c/1.jpg",
    url: "https://www.youtube.com/embed/0Ui9tf2Ld0c",
    title: "CDR Import Avoid Blank Call Types",
    alt: "video51.jpg",
  },

  //video [CDR Import]
  {
    src: "https://img.youtube.com/vi/IHjPIrYqqMA/1.jpg",
    url: "https://www.youtube.com/embed/IHjPIrYqqMA",
    title: "CDR Import",
    alt: "video52.jpg",
  },

  //video [Analysis Update data]
  {
    src: "https://img.youtube.com/vi/XbjSMc0s1P8/1.jpg",
    url: "https://www.youtube.com/embed/XbjSMc0s1P8",
    title: "Analysis Update data",
    alt: "video53.jpg",
  },

  //video [Add New CDR In Existing Analysis]
  {
    src: "https://img.youtube.com/vi/GTY_tXdbUBo/1.jpg",
    url: "https://www.youtube.com/embed/GTY_tXdbUBo",
    title: "Add New CDR In Existing Analysis",
    alt: "video54.jpg",
  },
];

const FDAPlaylist = [
  // video [Analysis Cumulative Mode]
  {
    src: "https://img.youtube.com/vi/doDa1EKVWe8/1.jpg",
    url: "https://www.youtube.com/embed/doDa1EKVWe8",
    title: "Analysis Cumulative Mode",
    alt: "video1.jpg",
  },

  // video [Analysis Import Account details]
  {
    src: "https://img.youtube.com/vi/Qk4xOA-dMXE/1.jpg",
    url: "https://www.youtube.com/embed/Qk4xOA-dMXE",
    title: "Analysis Import Account details",
    alt: "video2.jpg",
  },

  // video [Analysis main screen]
  {
    src: "https://img.youtube.com/vi/nturxz3wnMc/1.jpg",
    url: "https://www.youtube.com/embed/nturxz3wnMc",
    title: "Analysis main screen",
    alt: "video3.jpg",
  },

  // video [Create Analysis combined]
  {
    src: "https://img.youtube.com/vi/FTGxGsNtjxw/1.jpg",
    url: "https://www.youtube.com/embed/FTGxGsNtjxw",
    title: "Create Analysis combined",
    alt: "video4.jpg",
  },

  // video [Create Analysis single]
  {
    src: "https://img.youtube.com/vi/dC8l5x3_nLg/1.jpg",
    url: "https://www.youtube.com/embed/dC8l5x3_nLg",
    title: "Create Analysis single",
    alt: "video5.jpg",
  },

  // video [Dashboard]
  {
    src: "https://img.youtube.com/vi/JWUXKwWxUZk/1.jpg",
    url: "https://www.youtube.com/embed/JWUXKwWxUZk",
    title: "Dashboard",
    alt: "video6.jpg",
  },

  // video [Entity Mapping]
  {
    src: "https://img.youtube.com/vi/Nlsufx1OhtY/1.jpg",
    url: "https://www.youtube.com/embed/Nlsufx1OhtY",
    title: "Entity Mapping",
    alt: "video7.jpg",
  },

  // video [Import Excel]
  {
    src: "https://img.youtube.com/vi/0fTTsaJzyIU/1.jpg",
    url: "https://www.youtube.com/embed/0fTTsaJzyIU",
    title: "Import Excel",
    alt: "video8.jpg",
  },

  // video [Import html]
  {
    src: "https://img.youtube.com/vi/3wjxtBwC6H4/1.jpg",
    url: "https://www.youtube.com/embed/3wjxtBwC6H4",
    title: "Import html",
    alt: "video9.jpg",
  },

  // video [Import Text]
  {
    src: "https://img.youtube.com/vi/85O32N_4gek/1.jpg",
    url: "https://www.youtube.com/embed/85O32N_4gek",
    title: "Import Text",
    alt: "video10.jpg",
  },

  // video [ImportPDF]
  {
    src: "https://img.youtube.com/vi/HlgLVuaI2T0/1.jpg",
    url: "https://www.youtube.com/embed/HlgLVuaI2T0",
    title: "ImportPDF",
    alt: "video11.jpg",
  },

  // video [Login]
  {
    src: "https://img.youtube.com/vi/wt_ayJR_L_c/1.jpg",
    url: "https://www.youtube.com/embed/wt_ayJR_L_c",
    title: "Login",
    alt: "video12.jpg",
  },

  // video [Main Screen]
  {
    src: "https://img.youtube.com/vi/X7OKQEO9bUc/1.jpg",
    url: "https://www.youtube.com/embed/X7OKQEO9bUc",
    title: "Main Screen",
    alt: "video13.jpg",
  },

  // video [Manual Import]
  {
    src: "https://img.youtube.com/vi/RI9StRJpM9U/1.jpg",
    url: "https://www.youtube.com/embed/RI9StRJpM9U",
    title: "Manual Import",
    alt: "video14.jpg",
  },

  // video [Update 2nd sheet]
  {
    src: "https://img.youtube.com/vi/yJfUfgIRbj4/1.jpg",
    url: "https://www.youtube.com/embed/yJfUfgIRbj4",
    title: "Update 2nd sheet",
    alt: "video15.jpg",
  },
];

export { CDRPlaylist, FDAPlaylist };
