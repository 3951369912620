/*************************************** forgotEmail page *****************************************/

import {
  React,
  TextField,
  LoadingButton,
  useState,
  axios,
  swal,
} from "../commonFiles/Import";
import { FcLock } from "../commonFiles/Icons";

const ForgotEmail = () => {
  //used States
  const [usedStates, setUsedStates] = useState({
    //forgot details
    forgotDetails: {
      serialKey: "",
    },

    //error
    errors: {
      serialKeyError: "",
    },

    loading: false,
  });

  /*********************************************  destructering objects start ***********************************************/

  const { forgotDetails, loading, errors } = usedStates;

  const { serialKey } = forgotDetails;

  const { serialKeyError } = errors;

  /*********************************************  destructering objects end ***********************************************/

  //handle Submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: true,
        };
      });

      //send data
      const result = await axios.get("/forgot-email", {
        params: forgotDetails,
      });

      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          loading: false,
        };
      });

      swal({
        icon: result.data ? "info" : "error",
        title: result.data ? "Registered Email Address" : "Error",
        text: result.data ? `${result.data.Email}` : "No  Record Found.",
      }).then((value) => {
        if (value && result.data) {
          //reset all data
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              forgotDetails: {
                ...prevValue.forgotDetails,
                serialKey: "",
              },

              errors: {
                ...prevValue.errors,
                serialKeyError: "",
              },
            };
          });
        }
      });
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,

          loading: false,
        };
      });

      // show errors to user
      const { errors } = err.response.data;

      if (errors) {
        //destructering objects
        const { serialKeyError } = errors;

        //assign errors
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              serialKeyError,
            },
          };
        });
      } else {
        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  return (
    <>
      {/* left animation background */}
      <div className="left-bg-gradient"></div>

      {/* forgot password */}
      <section className="forgot-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100">
            <div className="col-xl-5 col-md-7 col-lg-6">
              <div className="card bg-glass">
                <div className="card-body text-center">
                  <FcLock size="100" className="mb-2" />
                  <h1 className=" card-title fs-2   fw-bold d-flex flex-column">
                    <span>Forgot</span>
                    <span>Your Email ?</span>
                  </h1>
                  <p className="mt-3">
                    Please enter serial key to get Email Address.
                  </p>

                  {/* forgot password  form */}
                  <form
                    method="POST"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                    className="form-group"
                  >
                    {/* email address */}
                    <TextField
                      label="Serial Key"
                      variant="standard"
                      name="serialKey"
                      id="serialKey"
                      className="w-100"
                      onChange={(event) => {
                        setUsedStates((prevValue) => {
                          return {
                            ...prevValue,
                            forgotDetails: {
                              ...prevValue.forgotDetails,
                              serialKey: event.target.value,
                            },
                            errors: {
                              ...prevValue.errors,
                              serialKeyError: "",
                            },
                          };
                        });
                      }}
                      value={serialKey}
                      error={serialKeyError ? true : false}
                      helperText={serialKeyError}
                    />

                    {/* send link */}
                    <LoadingButton
                      type="submit"
                      loading={loading}
                      loadingIndicator="Loading"
                      className={
                        loading
                          ? "primary-border shadow mt-3 "
                          : "primary-button shadow mt-3 "
                      }
                    >
                      submit
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* right animation background */}
      <div className="right-bg-gradient position-fixed  bottom-0"></div>
    </>
  );
};

export default ForgotEmail;
