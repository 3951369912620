/************************** execution starts for this file ****************************/
import {
  React,
  ReactDOM,
  App,
  BrowserRouter,
} from "./views/commonFiles/Import";

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById("root")
);
