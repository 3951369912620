/*******************************  Register Page ********************************************* */
import {
  React,
  TextField,
  LoadingButton,
  useEffect,
  plugin_for_contact,
  useState,
  Popover,
  swal,
  axios,
  $,
} from "../commonFiles/Import";
import { login } from "../commonFiles/Images";
import { FcInfo } from "../commonFiles/Icons";

const Register = () => {
  //used states
  const [usedStates, setUsedStates] = useState({
    // register details
    registerDetails: {
      name: "",
      contact: "",
      email: "",
      serialKey: "",
      password: "",
      confirmPassword: "",
    },

    // input Errors
    errors: {
      nameError: "",
      contactError: "",
      emailError: "",
      serialKeyError: "",
      passwordError: "",
      confirmPasswordError: "",
    },

    // addition States
    additionStates: {
      loading: false,
    },
  });

  // popover state
  const [anchorEl, setAnchorEl] = useState(null);

  /**************************destructering objects start **********************************/

  const { registerDetails } = usedStates;

  const { name, email, serialKey, password, confirmPassword } = registerDetails;

  const {
    nameError,
    contactError,
    emailError,
    serialKeyError,
    passwordError,
    confirmPasswordError,
  } = usedStates.errors;

  const { loading } = usedStates.additionStates;

  /**************************destructering objects end **********************************/

  // display country_code based on country in contact input_field
  useEffect(() => {
    plugin_for_contact(document.querySelector("#contact"));
  }, []);

  // change input fields based on [onchange ]
  const handleInputEvent = (event) => {
    const { name, value } = event.target;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        registerDetails: {
          ...prevValue.registerDetails,
          [name]: value,
        },
      };
    });
  };

  //reset Errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  //handle submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //contact Error
      const contact_error = plugin_for_contact(
        document.querySelector("#contact")
      );

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          additionStates: {
            ...prevValue.additionStates,
            loading: true,
          },
        };
      });

      //add type field
      Object.assign(registerDetails, { contact_error });

      //send data
      const result = await axios.post("/storeRegisterDetails", registerDetails);

      //on success
      if (result.data) {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            additionStates: {
              ...prevValue.additionStates,
              loading: false,
            },

            //reset Form
            registerDetails: {
              ...prevValue.registerDetails,
              name: "",
              email: "",
              contact: "",
              serialKey: "",
              password: "",
              confirmPassword: "",
            },

            //reset Errors
            errors: {
              ...prevValue.errors,
              nameError: "",
              contactError: "",
              emailError: "",
              serialKeyError: "",
              passwordError: "",
              confirmPasswordError: "",
            },
          };
        });

        const { success } = result.data;

        /* note : Due to country code library the contact field will not reset the value so, here forcibly 
        assigning empty string  */
        $("#contact").val("");

        /* again call this function */
        plugin_for_contact(document.querySelector("#contact"));

        //show success message to user
        swal({ title: "success", text: success, icon: "success" });
      }
    } catch (err) {
      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          additionStates: {
            ...prevValue.additionStates,
            loading: false,
          },
        };
      });

      // show errors to user
      const { errors } = err.response.data;

      if (errors) {
        //destructering objects
        const {
          nameError,
          contactError,
          emailError,
          serialKeyError,
          passwordError,
          confirmPasswordError,
        } = errors;

        //assign errors
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              nameError,
              contactError,
              emailError,
              serialKeyError,
              passwordError,
              confirmPasswordError,
            },
          };
        });
      } else {
        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };
  return (
    <>
      {/* left animation background */}
      <div className="left-bg-gradient position-fixed"></div>

      {/* register section */}
      <div className="register-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100 g-4">
            {/* image */}
            <div className=" col-xl-5 col-lg-6 col-md-6 ">
              <img src={login} alt="Login.svg" className="img-fluid" />
            </div>

            {/* content */}
            <div className="col-xl-4 col-lg-5 col-md-6 d-flex align-items-center mb-3  ">
              <div className="card bg-glass ">
                <div className="card-body">
                  {/* card title */}
                  <h1 className="card-title text-capitalize text-center fs-2">
                    sign up
                  </h1>

                  {/* register Form */}
                  <form
                    method="POST"
                    className="form-group"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    {/* Full Name */}
                    <TextField
                      label="Full Name*"
                      variant="standard"
                      name="name"
                      id="name"
                      className=" w-100 mt-2"
                      type="text"
                      onChange={(event) => {
                        handleInputEvent(event);
                        handleResetErrors("nameError");
                      }}
                      value={name}
                      error={nameError ? true : false}
                      helperText={nameError}
                    />

                    {/* contactNo */}
                    <TextField
                      variant="standard"
                      name="contact"
                      id="contact"
                      className=" w-100  mt-3"
                      type="tel"
                      onChange={(event) => {
                        handleInputEvent(event);
                        handleResetErrors("contactError");
                      }}
                      error={contactError ? true : false}
                      helperText={contactError}
                      //value={contact}
                    />

                    {/* Email Address*/}
                    <TextField
                      label="Email Address*"
                      variant="standard"
                      name="email"
                      id="email"
                      className=" w-100 mt-2"
                      type="email"
                      onChange={(event) => {
                        handleInputEvent(event);
                        handleResetErrors("emailError");
                      }}
                      value={email}
                      error={emailError ? true : false}
                      helperText={emailError}
                    />

                    {/* serial Key */}
                    <TextField
                      label="Serial Key*"
                      variant="standard"
                      name="serialKey"
                      id="serialKey"
                      className=" w-100 mt-2"
                      type="text"
                      onChange={(event) => {
                        handleInputEvent(event);
                        handleResetErrors("serialKeyError");
                      }}
                      value={serialKey}
                      error={serialKeyError ? true : false}
                      helperText={serialKeyError}
                    />

                    {/* password */}
                    <TextField
                      label="Password*"
                      variant="standard"
                      name="password"
                      id="password"
                      className=" w-100 mt-2"
                      type="password"
                      onChange={(event) => {
                        handleInputEvent(event);
                        handleResetErrors("passwordError");
                      }}
                      value={password}
                      error={passwordError ? true : false}
                      helperText={passwordError}
                    />

                    {/* display password rules */}
                    <FcInfo
                      size={20}
                      style={{
                        right: "1rem",
                        cursor: "pointer",
                        marginTop: "2rem",
                        color: "var(--primary-color)",
                      }}
                      aria-owns={
                        Boolean(anchorEl) ? "mouse-over-popover" : undefined
                      }
                      aria-haspopup="true"
                      onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                      onMouseLeave={() => setAnchorEl(null)}
                      className="text-primary position-absolute"
                    />

                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      onClose={() => setAnchorEl(null)}
                      disableRestoreFocus
                    >
                      <div className="p-3">
                        <span className="fw-bold">
                          The password must include
                        </span>
                        <ul style={{ listStyleType: "circle" }}>
                          <li>length should be minimum 8 characters.</li>
                          <li>atleast one numeric character.</li>
                          <li>atleast one Alphabetic letter.</li>
                          <li>atleast one capital letter.</li>
                          <li>one special character.</li>
                          <li>should not contain white space.</li>
                        </ul>
                      </div>
                    </Popover>

                    {/* Confirm Password */}
                    <TextField
                      label="Confirm Password*"
                      variant="standard"
                      name="confirmPassword"
                      id="confirmPassword"
                      className=" w-100 mt-2"
                      type="password"
                      onChange={(event) => {
                        handleInputEvent(event);
                        handleResetErrors("confirmPasswordError");
                      }}
                      value={confirmPassword}
                      error={confirmPasswordError ? true : false}
                      helperText={confirmPasswordError}
                    />

                    {/* submit button */}
                    <LoadingButton
                      type="submit"
                      variant="outlined"
                      loading={loading}
                      loadingIndicator="Loading"
                      className={
                        loading
                          ? " primary-border shadow   mt-3"
                          : " primary-button  shadow   mt-3  "
                      }
                    >
                      submit
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* right animation background */}
      <div className="right-bg-gradient position-fixed"></div>
    </>
  );
};

export default Register;
