/*******************************  DashBoard page ********************************************* */

import { React, useState, Drawer, SideBar } from "./Import";

import { CgMenu } from "./Icons";

// import { Prosoft_Logo } from "./Images";

const Header = (props) => {
  //drawer
  const [open, setOpen] = useState(false);

  // toggle drawer
  const closeDrawer = () => {
    setOpen(false);
  };

  return (
    <>
      {/* header section */}
      <header className="header-section fixed-top">
        <div className="container-fluid">
          <div className="row">
            <div className="icon d-flex justify-content-between">
              {/* hamburger icon */}
              <div className="hamburger-icon">
                <CgMenu
                  size={25}
                  onClick={() => setOpen(true)}
                  color="inherit"
                  cursor="pointer"
                />
              </div>

              {/* company logo */}
              <div className="company-logo">
                {/* <FcBusinessman size={25} /> */}
                {/* <img
                  src={Prosoft_Logo}
                  className="img-fluid"
                  alt="Prosoft_Logo.svg"
                  width="55"
                  height="5"
                /> */}
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* sidebar */}
      <Drawer anchor="left" open={open} onClose={closeDrawer}>
        <SideBar closeDrawer={closeDrawer} />
      </Drawer>
    </>
  );
};

export default Header;
