/************************ Routing can handle by this file ************************/

import {
  React,
  Switch,
  Route,
  HomePage,
  Redirect,
  Register,
  Activate,
  ForgotPassword,
  ResetPassword,
  Cookies,
  Videos,
  Tickets,
  PageNotFound,
  useHistory,
  useLocation,
  ForgotEmail,
} from "../views/commonFiles/Import";

const Router = () => {
  const history = useHistory();

  const location = useLocation();

  return (
    <>
      <Switch>
        {/* Home page */}
        <Route exact path="/">
          {Cookies.get("token") ? <Redirect to="/tickets" /> : <HomePage />}
        </Route>

        {/* register page */}
        <Route exact path="/register" component={Register}></Route>

        {/* activation page */}
        <Route exact path="/activate" component={Activate}></Route>

        {/* forgotPassword page */}
        <Route exact path="/forgot-password" component={ForgotPassword}></Route>

        {/* forgotEmail page */}
        <Route exact path="/forgot-email" component={ForgotEmail}></Route>

        {/* reset password  page*/}
        <Route
          exact
          path="/reset-password/:activationCode/"
          component={ResetPassword}
        ></Route>

        {/* ticket page */}
        <Route exact path="/tickets">
          {sessionStorage.getItem("name") ? (
            <Tickets />
          ) : Cookies.get("token") ? (
            <Tickets token={Cookies.get("token")} />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* video page */}
        <Route exact path="/videos">
          {sessionStorage.getItem("name") || Cookies.get("token") ? (
            <Videos />
          ) : (
            <Redirect to="/" />
          )}
        </Route>

        {/* page not found */}
        <Route component={PageNotFound} />
      </Switch>
    </>
  );
};

export default Router;
