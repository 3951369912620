/*******************************  player controller ********************************************* */

import {
  React,
  forwardRef,
  PropTypes,
  Slider,
  Typography,
} from "../commonFiles/Import";

import {
  BiFullscreen,
  BiRewind,
  AiFillPauseCircle,
  BiFastForward,
  IoMdSkipBackward,
  GiPauseButton,
  MdPlayArrow,
  MdSkipNext,
  MdVolumeOff,
  MdVolumeUp,
  FaYoutube,
} from "../commonFiles/Icons";

const PlayerControls = forwardRef(
  (
    {
      onPlayPause,
      playing,
      onRewind,
      onForward,
      onMute,
      muted,
      onToggleFullScreen,
      played,
      onSeekMouseUp,
      onSeekMouseDown,
      onSeek,
      elapsedTime,
      totalDuration,
      playNextVideo,
      playPreviousVideo,
    },
    ref
  ) => {
    function ValueLabelFormat(props) {
      const { value } = props;

      return <span title={value}>{value}</span>;
    }

    ValueLabelFormat.propTypes = {
      value: PropTypes.string.isRequired,
    };

    return (
      <div className="d-flex justify-content-center" ref={ref}>
        <div className="middlecontrols">
          <div className="d-flex justify-content-around">
            {/* Backword button */}
            <div className="d-flex align-items-center" onClick={onRewind}>
              <BiRewind title="backward 10 sec" style={{ fontSize: "40px" }} />
            </div>

            {/* play button */}
            <div
              className="play-button d-flex align-items-center"
              style={{ color: "red" }}
              onClick={onPlayPause}
            >
              {playing ? (
                <AiFillPauseCircle title="pause" size={77} />
              ) : (
                <FaYoutube title="play" size={77} />
              )}
            </div>

            {/* forward button */}
            <div className="d-flex align-items-center" onClick={onForward}>
              <BiFastForward
                title="forward 10 sec"
                style={{ fontSize: "40px" }}
              />
            </div>
          </div>
        </div>

        <div className="bottomcontrols">
          <div className="row">
            {/* slideBar */}
            <div className="col-12">
              <Slider
                className=" slider"
                style={{ width: "99%", marginLeft: "0.5rem" }}
                min={0}
                max={100}
                valueLabelFormat={(props) => (
                  <ValueLabelFormat {...props} value={elapsedTime} />
                )}
                valueLabelDisplay="auto"
                value={played * 100}
                onChange={onSeek}
                onMouseDown={onSeekMouseDown}
                onChangeCommitted={onSeekMouseUp}
              />
            </div>

            <div className="col-12  mb-1">
              <div className="row">
                <div className=" col-3 d-flex justify-content-between ">
                  {/* prev button */}
                  <div
                    className="button d-flex align-items-center"
                    onClick={playPreviousVideo}
                  >
                    <IoMdSkipBackward title="previous video" />
                  </div>

                  {/* play button */}
                  <div
                    className="button d-flex align-items-center"
                    onClick={onPlayPause}
                  >
                    {playing ? (
                      <GiPauseButton title="pause" />
                    ) : (
                      <MdPlayArrow title="play" />
                    )}
                  </div>

                  {/* next button */}
                  <div
                    className=" button d-flex align-items-center"
                    onClick={playNextVideo}
                  >
                    <MdSkipNext title="next video" />
                  </div>

                  {/* volume button */}
                  <div
                    className="button d-flex align-items-center"
                    onClick={onMute}
                  >
                    {muted ? (
                      <MdVolumeOff title="volume off" />
                    ) : (
                      <MdVolumeUp title="volume up" />
                    )}
                  </div>

                  {/* video-duration */}
                  <div className="button d-flex align-items-center">
                    <Typography>
                      {elapsedTime}/{totalDuration}
                    </Typography>
                  </div>
                </div>

                <div className="col d-flex justify-content-end">
                  {/*full screen */}
                  <div
                    className="button d-flex align-items-center"
                    onClick={onToggleFullScreen}
                  >
                    <BiFullscreen title="full screen" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PlayerControls;
