/*******************************  Tickets page **********************************************/

import {
  React,
  TextField,
  useState,
  Popover,
  LoadingButton,
  MaterialTable,
  useEffect,
  plugin_for_contact,
  useCallback,
  axios,
  swal,
  Header,
  MenuItem,
  jwtDecode,
  moment,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Carousel,
  checkCookieExpiry,
  useHistory,
  Cookies,
} from "../commonFiles/Import";

import { FcInfo, BiRefresh, CgClose } from "../commonFiles/Icons";

const Tickets = (props) => {
  //used for redirecting page
  const history = useHistory();

  const [usedStates, setUsedStates] = useState({
    additonalStates: {
      //loading button
      loading: false,

      //dialog
      dialogBox: {
        open: false,
        dialogTitle: "",
        maxWidth: "",
        reopenLoading: false,
      },

      //attachments data
      attachmentData: [],
    },

    // reopen details
    reopenTicketDetails: {
      ticketNo: "",
      reason: "",
    },

    // form details
    formDetails: {
      contact: "",
      email: "",
      name: "",
      problemFaced: "",
      problemAttachements: "",
      AMCStatus: sessionStorage.getItem("AMCStatus")
        ? sessionStorage.getItem("AMCStatus")
        : jwtDecode(props.token).userDetails[0].AMC_status,

      edition: sessionStorage.getItem("edition")
        ? sessionStorage.getItem("edition")
        : jwtDecode(props.token).userDetails[0].Edition,

      maintenanceCode: sessionStorage.getItem("maintenanceCode")
        ? sessionStorage.getItem("maintenanceCode")
        : jwtDecode(props.token).userDetails[0].Maintenance_code,

      serialKey: sessionStorage.getItem("serialKey")
        ? sessionStorage.getItem("serialKey")
        : jwtDecode(props.token).userDetails[0].SerialKey,

      userid: sessionStorage.getItem("userid")
        ? sessionStorage.getItem("userid")
        : jwtDecode(props.token).userDetails[0].Id,

      productType: sessionStorage.getItem("productType")
        ? sessionStorage.getItem("productType")
        : jwtDecode(props.token).userDetails[0].Product,
    },

    // table data
    ticketTableDetails: [],

    // input Errors
    errors: {
      contactError: "",
      nameError: "",
      attachmentError: "",
      emailError: "",
      problemFacedError: "",
      reasonError: "",
    },
  });

  // popover state
  const [anchorEl, setAnchorEl] = useState(null);

  // change input fields based on [onchange ]
  const handleInputEvent = (event) => {
    const { name, type } = event.target;

    const value = type === "file" ? event.target.files : event.target.value;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        formDetails: {
          ...prevValue.formDetails,
          [name]: value,
        },
      };
    });
  };

  //reset Errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  /********************* destructering objects start *************** */

  const {
    additonalStates,
    errors,
    formDetails,
    ticketTableDetails,
    reopenTicketDetails,
  } = usedStates;

  const { loading, dialogBox, attachmentData } = additonalStates;

  const {
    contactError,
    emailError,
    nameError,
    attachmentError,
    problemFacedError,
    reasonError,
  } = errors;

  const {
    contact,
    email,
    name,
    serialKey,
    problemFaced,
    problemAttachements,
    AMCStatus,
    edition,
    maintenanceCode,
    userid,
    productType,
  } = formDetails;

  const { open, dialogTitle, maxWidth, reopenLoading } = dialogBox;

  const { ticketNo, reason } = reopenTicketDetails;

  /********************* destructering objects end *************** */

  // update form states based on login
  const updateFormStates = useCallback(() => {
    try {
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          formDetails: {
            ...prevValue.formDetails,
            name: sessionStorage.getItem("name")
              ? sessionStorage.getItem("name")
              : jwtDecode(props.token).userDetails[0].Name,
            email: sessionStorage.getItem("email")
              ? sessionStorage.getItem("email")
              : jwtDecode(props.token).userDetails[0].Email,
            contact: sessionStorage.getItem("contact")
              ? sessionStorage.getItem("contact")
              : jwtDecode(props.token).userDetails[0].Contact,
          },
        };
      });
    } catch (err) {
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  }, [props]);

  //get allticket details based on userid
  const getTicketDetails = useCallback(async () => {
    try {
      // count records
      let count = 0;

      const result = await axios.get("/getTicketDetails", {
        params: { userid },
      });

      //on success
      if (result.data) {
        //add count field
        result.data.forEach((element) => {
          Object.assign(element, { Sr_No: ++count });
        });

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            ticketTableDetails: result.data,
          };
        });
      }
    } catch (err) {
      // display error rather than input errors
      err.response
        ? swal({
            title: `${err.response.status}`,
            text: `${err.response.data}`,
            icon: "error",
          })
        : swal({
            title: `Error`,
            text: `${err}`,
            icon: "error",
          });
    }
  }, [userid]);

  useEffect(() => {
    //check if cookie is expired
    if (checkCookieExpiry()) {
      swal({
        title: "Sorry !!!",
        text: "Your session is expired please Login.",
        timer: 4000,
        buttons: false,
        icon: "info",
      }).then(
        () =>
          //reset session
          window.sessionStorage.clear(),

        //destroy cookies
        Cookies.get("token")
          ? Cookies.remove("token", { expires: Date.now() })
          : null,

        // redirect to home page
        history.push("/")
      );
    } else {
      plugin_for_contact(document.querySelector("#contact"));

      updateFormStates();

      getTicketDetails();
    }
  }, [updateFormStates, getTicketDetails, history]);

  //show attachment in full screen
  const showFullScreen = (props) => {
    //intialize array
    let response = [];

    //multiple attachments
    if (props.includes(",")) {
      //split data
      var attachments = props.split(",");

      attachments.forEach((data) => {
        response.push({
          src: data,
          alt: "attachment",
        });
      });
    }

    //single attachment
    else {
      response.push({ src: props, alt: "attachments" });
    }

    //open dialog
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        additonalStates: {
          ...prevValue.additonalStates,
          attachmentData: response,
          dialogBox: {
            ...prevValue.additonalStates.dialogBox,
            open: true,
            dialogTitle: "Problem Attachments",
            maxWidth: "lg",
          },
        },
      };
    });
  };

  //reopen ticket form
  const reopenTicketForm = (props) => {
    //assign data
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        additonalStates: {
          ...prevValue.additonalStates,
          dialogBox: {
            ...prevValue.additonalStates.dialogBox,
            open: true,
            dialogTitle: "Reopen Ticket",
            maxWidth: "sm",
          },
        },
        reopenTicketDetails: {
          ...prevValue.reopenTicketDetails,
          ticketNo: props,
          reason: "",
        },

        //reset errors
        errors: {
          ...prevValue.errors,
          reasonError: "",
        },
      };
    });
  };

  // ticketColumns table columns
  const ticketColumns = [
    {
      title: "Sr No",
      field: "Sr_No",
    },
    {
      title: "User Name",
      field: "Name",
    },
    {
      title: "Email ID",
      field: "Email",
    },
    {
      title: "Contact No",
      field: "Contact",
    },
    {
      title: "Ticket No",
      field: "ComplaintNo",
    },
    {
      title: "Problem Faced",
      field: "Problem_faced",
    },
    {
      title: "Problem Attachments",
      field: "CD_attachment_path",
      render: (rowData) => {
        return rowData.CD_attachment_path ? (
          <Button
            type="button"
            size="small"
            className="primary-button"
            onClick={() => showFullScreen(rowData.CD_attachment_path)}
          >
            view
          </Button>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Ticket Raised DateTime",
      field: "Comp_date_time",
      render: (rowData) => {
        return moment(rowData.Comp_date_time, "YYYY-MM-DD hh:mm:ss a").format(
          "DD/MM/YYYY hh:mm:ss a"
        );
      },
    },
    {
      title: "Solution Provided",
      field: "Solution_provided",
      render: (rowData) => {
        return rowData.Solution_provided ? rowData.Solution_provided : "-";
      },
    },
    {
      title: "Agent Name",
      field: "Agent_name",
      render: (rowData) => {
        return rowData.Agent_name ? rowData.Agent_name : "-";
      },
    },
    {
      title: "Status",
      field: "Status",
      render: (rowData) => {
        return rowData.Status === "Closed" ? (
          <TextField
            variant="standard"
            fullWidth
            value={rowData.status}
            select
            defaultValue={rowData.Status}
            onChange={(event) => reopenTicketForm(event.target.value)}
          >
            <MenuItem
              className="w-100 p-1 d-flex justify-content-start"
              value={rowData.Status}
            >
              {rowData.Status}
            </MenuItem>

            <MenuItem
              className="w-100 p-1 d-flex justify-content-start"
              value={rowData.ComplaintNo}
            >
              Re_open
            </MenuItem>
          </TextField>
        ) : (
          <span className="text-danger fw-bold">{rowData.Status}</span>
        );
      },
    },

    {
      title: "",
      field: "Status",
      render: (rowData) => {
        return rowData.Status === "Pending" ? (
          <div>
            <span className="fw-bold"> Reason : </span>
            <span>{rowData.Remark}</span>
            <br />

            <span className="fw-bold"> Date-time : </span>
            <span>
              {rowData.Action_datetime
                ? moment(
                    rowData.Action_datetime,
                    "YYYY-MM-DD hh:mm:ss a"
                  ).format("DD/MM/YYYY hh:mm:ss a")
                : "-"}
            </span>
          </div>
        ) : rowData.Status === "Re_open" ? (
          <div>
            <span className="fw-bold"> Reason : </span>
            <span>{rowData.Reopen_reason}</span>
            <br />

            <span className="fw-bold"> Date-time : </span>
            <span>
              {rowData.Reopen_date_time
                ? moment(
                    rowData.Reopen_date_time,
                    "YYYY-MM-DD hh:mm:ss a"
                  ).format("DD/MM/YYYY hh:mm:ss a")
                : "-"}
            </span>
          </div>
        ) : (
          "-"
        );
      },
    },
  ];

  //on submit
  const handleSubmit = async (event, props) => {
    try {
      event.preventDefault();

      //check which type of form is  submitted
      const { type } = props;

      //add new Ticket
      if (type === "newTicket") {
        //contact Error
        const contact_error = plugin_for_contact(
          document.querySelector("#contact")
        );

        //enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            additonalStates: {
              ...prevValue.additonalStates,
              loading: true,
            },
          };
        });

        //if you want to upload files in server this method is mandatory.
        const formData = new FormData();

        formData.append("name", name);
        formData.append("contact", contact);
        formData.append("email", email);
        formData.append("problemFaced", problemFaced);
        formData.append("AMCStatus", AMCStatus);
        formData.append("edition", edition);
        formData.append("maintenanceCode", maintenanceCode);
        formData.append("serialKey", serialKey);
        formData.append("userid", userid);
        formData.append("productType", productType);
        formData.append("contact_error", contact_error);

        //problem_attachments
        if (problemAttachements.length > 0) {
          for (var i = 0; i < problemAttachements.length; i++) {
            formData.append("problemAttachements", problemAttachements[i]);
          }
        }

        //send data
        const result = await axios.post("/storeTicketDetails", formData);

        //on success
        if (result.data.success) {
          //reset varaibles
          setUsedStates((prevValue) => {
            return {
              ...prevValue,

              //disable loading
              additonalStates: {
                ...prevValue.additonalStates,
                loading: false,
              },

              //reset form
              formDetails: {
                ...prevValue.formDetails,
                contact: "",
                email: "",
                name: "",
                problemFaced: "",
                problemAttachements: "",
                AMCStatus: sessionStorage.getItem("AMCStatus")
                  ? sessionStorage.getItem("AMCStatus")
                  : jwtDecode(props.token).userDetails[0].AMC_status,

                edition: sessionStorage.getItem("edition")
                  ? sessionStorage.getItem("edition")
                  : jwtDecode(props.token).userDetails[0].Edition,

                maintenanceCode: sessionStorage.getItem("maintenanceCode")
                  ? sessionStorage.getItem("maintenanceCode")
                  : jwtDecode(props.token).userDetails[0].Maintenance_code,

                serialKey: sessionStorage.getItem("serialKey")
                  ? sessionStorage.getItem("serialKey")
                  : jwtDecode(props.token).userDetails[0].SerialKey,

                userid: sessionStorage.getItem("userid")
                  ? sessionStorage.getItem("userid")
                  : jwtDecode(props.token).userDetails[0].Id,
              },

              //reset Errors
              errors: {
                ...prevValue.errors,
                contactError: "",
                nameError: "",
                attachmentError: "",
                emailError: "",
                problemFacedError: "",
              },
            };
          });

          //update formStates
          updateFormStates();

          //get ticket details based on userid
          getTicketDetails();

          // show message to user
          swal({
            title: "success",
            text: "Your ticket is recorded, we will solve your issue very soon",
            icon: "success",
          });
        }
      }

      //reopen ticket
      if (type === "reopenTicket") {
        //enable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            additonalStates: {
              ...prevValue.additonalStates,
              dialogBox: {
                ...prevValue.additonalStates.dialogBox,
                reopenLoading: true,
              },
            },
          };
        });

        //send data
        const result = await axios.patch(
          "/updateReopenData",
          reopenTicketDetails
        );

        //on success
        if (result.data.success) {
          //disable loading
          setUsedStates((prevValue) => {
            return {
              ...prevValue,
              additonalStates: {
                ...prevValue.additonalStates,
                dialogBox: {
                  ...prevValue.additonalStates.dialogBox,
                  reopenLoading: false,
                  open: false,
                },
              },

              //reset form
              reopenTicketDetails: {
                ...prevValue.reopenTicketDetails,
                ticketNo: "",
                reason: "",
              },

              //reset errors
              errors: {
                ...prevValue.errors,
                reasonError: "",
              },
            };
          });

          //get ticket details based on userid
          getTicketDetails();

          // show message to user
          swal({
            title: "success",
            text: "Your ticket is reopened, we will solve your issue very soon",
            icon: "success",
          });
        }
      }
    } catch (err) {
      //check which type of form is  submitted
      const { type } = props;

      //add new Ticket
      if (type === "newTicket") {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            additonalStates: {
              ...prevValue.additonalStates,
              loading: false,
            },
          };
        });
      }

      //reopen ticket
      if (type === "reopenTicket") {
        //disable loading
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            additonalStates: {
              ...prevValue.additonalStates,
              dialogBox: {
                ...prevValue.additonalStates.dialogBox,
                reopenLoading: false,
              },
            },
          };
        });
      }

      // show errors to user
      const { errors } = err.response.data;

      if (errors) {
        //destructering objects
        const {
          attachmentError,
          contactError,
          emailError,
          nameError,
          problemFacedError,
          reasonError,
        } = errors;

        //assign errors
        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              attachmentError,
              contactError,
              emailError,
              nameError,
              problemFacedError,
              reasonError,
            },
          };
        });
      }

      // display error rather than input errors
      else {
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  return (
    <>
      <Header />

      {/* left animation background */}
      <div className="left-bg-gradient "></div>

      <section className="complaint-section" style={{ marginTop: "6rem" }}>
        <div className="container-fluid">
          <div className="row g-3">
            {/* new Ticket */}
            <div className="col-md-5">
              <div className="card bg-glass h-100">
                <div className="card-header">
                  <h5 className="card-title fw-bold">Add New Ticket</h5>
                </div>
                <div className="card-body">
                  <form
                    method="POST"
                    className="form-group"
                    autoComplete="off"
                    onSubmit={(event) =>
                      handleSubmit(event, { type: "newTicket" })
                    }
                    encType="multipart/form-data"
                  >
                    {/* Serial Key */}
                    <TextField
                      label="Serial Key*"
                      variant="standard"
                      className="w-100"
                      name="serialKey"
                      id="serialKey"
                      type="text"
                      value={serialKey}
                      InputProps={{
                        readOnly: true,
                      }}
                    />

                    {/* user Name */}
                    <TextField
                      label="User Name*"
                      variant="standard"
                      className="w-100 mt-2"
                      name="name"
                      id="name"
                      type="text"
                      value={name}
                      onChange={(event) => {
                        handleInputEvent(event);

                        handleResetErrors("nameError");
                      }}
                      error={nameError ? true : false}
                      helperText={nameError}
                    />

                    {/* Email id */}
                    <TextField
                      label="Email ID*"
                      variant="standard"
                      className="w-100 mt-2"
                      name="email"
                      id="email"
                      type="email"
                      value={email}
                      onChange={(event) => {
                        handleInputEvent(event);

                        handleResetErrors("emailError");
                      }}
                      error={emailError ? true : false}
                      helperText={emailError}
                    />

                    {/* contact No */}
                    <TextField
                      variant="standard"
                      name="contact"
                      id="contact"
                      className=" w-100 mt-3"
                      type="tel"
                      onChange={(event) => {
                        handleInputEvent(event);

                        handleResetErrors("contactError");
                      }}
                      error={contactError ? true : false}
                      helperText={contactError}
                      value={contact}
                    />

                    {/* Problem Faced */}
                    <TextField
                      label="Problem Faced*"
                      variant="standard"
                      className="w-100 mt-2"
                      name="problemFaced"
                      id="problemFaced"
                      multiline
                      rows={5}
                      value={problemFaced}
                      onChange={(event) => {
                        handleInputEvent(event);

                        handleResetErrors("problemFacedError");
                      }}
                      error={problemFacedError ? true : false}
                      helperText={problemFacedError}
                    />

                    {/* Attachments */}
                    <TextField
                      className="w-100 mt-4"
                      variant="standard"
                      type="file"
                      name="problemAttachements"
                      id="problemAttachements"
                      onChange={(event) => {
                        handleInputEvent(event);

                        handleResetErrors("attachmentError");
                      }}
                      inputProps={{
                        accept: "image/png,image/jpeg",
                        multiple: true,
                      }}
                      error={attachmentError ? true : false}
                      helperText={attachmentError}
                    />

                    {/********************************* Attachment rules  start  *********************** */}
                    <FcInfo
                      style={{
                        right: "1rem",
                        cursor: "pointer",
                        marginTop: "2rem",
                        color: "var(--primary-color)",
                      }}
                      size={20}
                      aria-owns={
                        Boolean(anchorEl) ? "mouse-over-popover" : undefined
                      }
                      aria-haspopup="true"
                      onMouseEnter={(e) => setAnchorEl(e.currentTarget)}
                      onMouseLeave={() => setAnchorEl(null)}
                      className="text-primary position-absolute"
                    />

                    <Popover
                      id="mouse-over-popover"
                      sx={{
                        pointerEvents: "none",
                      }}
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "center",
                        horizontal: "left",
                      }}
                      onClose={(e) => setAnchorEl(null)}
                      disableRestoreFocus
                    >
                      <div className="p-3">
                        <span className="fw-bold">Attachment Rules</span>
                        <ul style={{ listStyleType: "circle" }}>
                          <li>Attachment must be [image/png, image/jpeg].</li>
                          <li>
                            Only allowed to upload a maximum of 5 Attachments.
                          </li>
                          <li>Attachment must be less than 4MB.</li>
                        </ul>
                      </div>
                    </Popover>

                    {/********************************* Attachment Rules  end  *********************** */}

                    {/* submit button */}
                    <LoadingButton
                      type="submit"
                      variant="outlined"
                      loading={loading}
                      loadingIndicator="Loading"
                      className={
                        loading
                          ? "primary-border shadow  mt-4"
                          : "primary-button  shadow   mt-4  "
                      }
                    >
                      submit
                    </LoadingButton>
                  </form>
                </div>
              </div>
            </div>

            {/* view tickets */}
            <div className="col-md-7">
              <MaterialTable
                title={<h5 className="fw-bold">My Tickets</h5>}
                columns={ticketColumns}
                actions={[
                  {
                    icon: () => <BiRefresh />,
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () => getTicketDetails(),
                  },
                ]}
                options={{
                  pageSizeOptions: [7, 10, 20, 100],
                  pageSize: 7,

                  headerStyle: {
                    backgroundColor: "#dbe9fa",
                    color: "black",
                    whiteSpace: "nowrap",
                    fontWeight: "bold",
                    position: "sticky",
                    zIndex: 100,
                  },
                  maxBodyHeight: 430,
                  rowStyle: {
                    whiteSpace: "nowrap",
                  },
                }}
                data={ticketTableDetails}
              />
            </div>
          </div>
        </div>
      </section>

      {/* right animation background */}
      <div className="right-bg-gradient bottom-0 position-fixed "></div>

      {/* dialog box */}
      <Dialog open={open} fullWidth maxWidth={maxWidth} className="dialog-box">
        <DialogTitle className="d-flex flex-row justify-content-between text-white ">
          <span className="fs-4 fw-bold">{dialogTitle}</span>
          <CgClose
            fontSize={30}
            style={{ cursor: "pointer" }}
            onClick={() =>
              setUsedStates((prevValue) => {
                return {
                  ...prevValue,
                  additonalStates: {
                    ...prevValue.additonalStates,
                    dialogBox: {
                      ...prevValue.additonalStates.dialogBox,
                      open: false,
                    },
                  },
                };
              })
            }
          />
        </DialogTitle>

        <DialogContent dividers>
          {dialogTitle === "Problem Attachments" ? (
            //ifh there is only one attachment
            attachmentData.length === 1 ? (
              <div className="text-center">
                <img
                  src={attachmentData[0].src}
                  alt={attachmentData[0].alt}
                  className="img-fluid w-75 h-75"
                />
              </div>
            ) : (
              <Carousel>
                {attachmentData.map((data, index) => {
                  return (
                    <Carousel.Item key={index} className="text-center">
                      <img
                        src={data.src}
                        alt={data.alt}
                        className="img-fluid w-75 h-75"
                      />
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            )
          ) : (
            <form
              method="POST"
              autoComplete="off"
              className="form-group"
              onSubmit={(event) =>
                handleSubmit(event, { type: "reopenTicket" })
              }
            >
              {/* ticket No */}
              <TextField
                variant="standard"
                label="ticket No*"
                name="ticketNo"
                id="ticketNo"
                className="w-100 mt-2"
                value={ticketNo}
                inputProps={{
                  readOnly: true,
                }}
              />

              {/* reason to reopen */}
              <TextField
                variant="standard"
                label="Reason*"
                name="reason"
                id="reason"
                className="mt-2 w-100"
                multiline
                rows={4}
                value={reason}
                error={reasonError ? true : false}
                helperText={reasonError}
                onKeyPress={() => handleResetErrors("reasonError")}
                onChange={(event) =>
                  setUsedStates((prevValue) => {
                    return {
                      ...prevValue,
                      reopenTicketDetails: {
                        ...prevValue.reopenTicketDetails,
                        reason: event.target.value,
                      },
                    };
                  })
                }
              />

              {/* submit button */}
              <LoadingButton
                type="submit"
                variant="outlined"
                loading={reopenLoading}
                loadingIndicator="Loading"
                className={
                  reopenLoading
                    ? "success-border shadow w-100  mt-4"
                    : "success-button  shadow w-100  mt-4  "
                }
              >
                submit
              </LoadingButton>
            </form>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Tickets;
