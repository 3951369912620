/*******************************  Homepage ********************************************* */

import {
  React,
  FormControlLabel,
  Checkbox,
  LoadingButton,
  Link,
  useState,
  axios,
  swal,
  useHistory,
  Button,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
} from "../commonFiles/Import";
import { login } from "../commonFiles/Images";

const HomePage = () => {
  // used for redirecting routes
  const history = useHistory();

  //used states
  const [usedStates, setUsedStates] = useState({
    //login details
    loginDetails: {
      email: "",
      password: "",
      rememberMe: false,
    },

    //additianal states
    additionalSates: {
      //button loading
      loading: false,
    },

    //errors
    errors: {
      emailError: "",
      passwordError: "",
    },
  });

  /******************************* destructering objects start *****************************/
  const { loginDetails, additionalSates, errors } = usedStates;

  const { rememberMe } = loginDetails;

  const { loading } = additionalSates;

  const { emailError, passwordError } = errors;

  /******************************* destructering objects end *****************************/

  // change input fields based on [onchange ]
  const handleInputEvent = (event) => {
    const value =
      event.target.type === "checkbox"
        ? event.target.checked
        : event.target.value;

    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        loginDetails: {
          ...prevValue.loginDetails,
          [event.target.name]: value,
        },
      };
    });
  };

  //reset errors
  const handleResetErrors = (props) => {
    setUsedStates((prevValue) => {
      return {
        ...prevValue,
        errors: {
          ...prevValue.errors,
          [props]: "",
        },
      };
    });
  };

  //on submit
  const handleSubmit = async (event) => {
    try {
      event.preventDefault();

      //enable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          additionalSates: {
            ...prevValue.additionalSates,
            loading: true,
          },
        };
      });

      //send data
      const result = await axios.get("/login", {
        params: loginDetails,
      });

      // on sucess pass user to welcome page
      if (result.data) {
        const {
          AMC_status,
          Contact,
          Edition,
          Email,
          Id,
          Maintenance_code,
          Name,
          SerialKey,
          cookieExpiry,
          Product,
        } = result.data;

        // store userdata in session
        sessionStorage.setItem("name", Name);
        sessionStorage.setItem("AMCStatus", AMC_status);
        sessionStorage.setItem("contact", Contact);
        sessionStorage.setItem("edition", Edition);
        sessionStorage.setItem("email", Email);
        sessionStorage.setItem("userid", Id);
        sessionStorage.setItem("maintenanceCode", Maintenance_code);
        sessionStorage.setItem("serialKey", SerialKey);
        sessionStorage.setItem(
          "cookieExpiry",
          cookieExpiry ? cookieExpiry : ""
        );
        sessionStorage.setItem(
          "productType",

          Product === "ProFDAnalyzer"
            ? "FDA"
            : Product.toLowerCase() === "c5cat" ||
              Product === "C5CDRAnalyzer_V6"
            ? "C5CAT"
            : "C5CDR"
        );

        //redirect to welcome page
        history.push("/tickets");
      }
    } catch (err) {
      // show errors to user
      const { loginError, errors } = err.response.data;

      //disable loading
      setUsedStates((prevValue) => {
        return {
          ...prevValue,
          additionalSates: {
            ...prevValue.additionalSates,
            loading: false,
          },
        };
      });

      if (errors) {
        const { emailError, passwordError } = errors;

        setUsedStates((prevValue) => {
          return {
            ...prevValue,
            errors: {
              ...prevValue.errors,
              emailError,
              passwordError,
            },
          };
        });
      } else if (loginError) {
        swal({ title: "Error", text: `${loginError}`, icon: "error" });
      } else {
        // display error rather than input errors
        err.response
          ? swal({
              title: `${err.response.status}`,
              text: `${err.response.data}`,
              icon: "error",
            })
          : swal({
              title: `Error`,
              text: `${err}`,
              icon: "error",
            });
      }
    }
  };

  return (
    <>
      {/* left animation background */}
      <div className="left-bg-gradient"></div>

      {/* login section */}
      <div className="login-section">
        <div className="container">
          <div className="row d-flex justify-content-center align-items-center min-vh-100 g-4">
            {/* image */}
            <div className=" col-xl-5 col-lg-6 col-md-6  ">
              <img src={login} alt="Login.svg" className="img-fluid" />
            </div>

            {/* content */}
            <div className="col-xl-4 col-lg-5 col-md-6   d-flex align-items-center mb-3 ">
              <div className="card bg-glass">
                <div className="card-body">
                  {/* card title */}
                  <h1 className="card-title text-capitalize text-center fs-2">
                    sign in
                  </h1>

                  {/* login Form */}
                  <form
                    method="POST"
                    className="form-group"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <div className="row">
                      {/* Email Address */}
                      <div className="col-sm-12 mt-2">
                        <FormControl fullWidth variant="standard">
                          <InputLabel htmlFor="email">Email Address</InputLabel>
                          <Input
                            name="email"
                            id="email"
                            onChange={(event) => {
                              handleInputEvent(event);
                              handleResetErrors("emailError");
                            }}
                            type="email"
                            error={emailError ? true : false}
                            endAdornment={
                              <InputAdornment position="end">
                                <>
                                  <small>|</small>

                                  <Button
                                    variant="text"
                                    className="text-lowercase"
                                    size="small"
                                    onClick={() =>
                                      history.push("/forgot-email")
                                    }
                                  >
                                    forgot?
                                  </Button>
                                </>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <small style={{ color: "var(--error-color)" }}>
                          {emailError}
                        </small>
                      </div>

                      {/* password */}
                      <div className="col-sm-12 mt-2">
                        <FormControl fullWidth variant="standard">
                          <InputLabel htmlFor="password">Password</InputLabel>
                          <Input
                            name="password"
                            id="password"
                            onChange={(event) => {
                              handleInputEvent(event);
                              handleResetErrors("passwordError");
                            }}
                            type="password"
                            error={passwordError ? true : false}
                            endAdornment={
                              <InputAdornment position="end">
                                <>
                                  <small> |</small>

                                  <Button
                                    variant="text"
                                    className="text-lowercase"
                                    size="small"
                                    onClick={() =>
                                      history.push("/forgot-password")
                                    }
                                  >
                                    forgot?
                                  </Button>
                                </>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                        <small style={{ color: "var(--error-color)" }}>
                          {passwordError}
                        </small>
                      </div>

                      {/* remember me  & forgot password */}
                      <div className="col-sm-12 mt-2 d-flex flex-row align-items-center justify-content-between">
                        <FormControlLabel
                          control={
                            <Checkbox
                              name="rememberMe"
                              id="rememberMe"
                              checked={rememberMe}
                              onChange={handleInputEvent}
                              value={rememberMe}
                            />
                          }
                          label="Remember Me"
                        />
                      </div>

                      {/* login button */}
                      <div className="col-sm-12">
                        <LoadingButton
                          type="submit"
                          variant="outlined"
                          loading={loading}
                          loadingIndicator="Loading"
                          className={
                            loading
                              ? " primary-border shadow   mt-2"
                              : " primary-button  shadow   mt-2  "
                          }
                        >
                          Login
                        </LoadingButton>
                      </div>

                      {/* register link */}
                      <div className="col-sm-12">
                        <p className="mt-2">
                          Don't have an account ?{" "}
                          <Link to="/register">register</Link>
                        </p>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* right animation background */}
      <div className="right-bg-gradient position-fixed bottom-0 "></div>
    </>
  );
};

export default HomePage;
