/**********initial plugin for contact number********************************/

import { intlTelInput, util, moment } from "../../views/commonFiles/Import";

// plugin for contact field
const plugin_for_contact = (props) => {
  // initialise plugin
  const iti = intlTelInput(props, {
    initialCountry: "in",
    autoHideDialCode: false,
    autoPlaceholder: false,
    nationalMode: false,
    utilsScript: util,
    // customPlaceholder: function (selectedCountryPlaceholder) {
    //   return "e.g. " + selectedCountryPlaceholder;
    // },
  });

  // check whether entered contact number is correct or not based on country code
  if (props.value.length < 5) {
    return "Please fill out this field.";
  } else {
    return iti.isValidNumber() === false
      ? "Please enter valid contact number"
      : "";
  }
};

//cookie expiry
const checkCookieExpiry = () => {
  if (sessionStorage.getItem("cookieExpiry")) {
    if (moment(new Date()).isAfter(sessionStorage.getItem("cookieExpiry")))
      return true;
  }
};

export { plugin_for_contact, checkCookieExpiry };
